import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import Pagination from '../../components/Common/Pagination';
import { Row, Col, Button } from 'reactstrap';
import { FaTrashAlt } from '@react-icons/all-files/fa/FaTrashAlt';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import PrivateRoute from '../../components/Security/privateRoute';

import { selectAllFilters } from '@a1-ict/fsbo/searchSlice/selectors';
import { fetchUserSearches } from '@a1-ict/fsbo/userSlice';
import { selectUserSearches } from '@a1-ict/fsbo/userSlice/selectors';
import { settings } from '../../settings';
import { snakeCase } from 'lodash';
import queryString from 'query-string';

const getName = (param, filters) => {
  const filter = filters.find((filter) => filter.name === param);

  if (filter) return filter.text;
  param = param.toLowerCase();

  if (param === 'coordinates') return 'Координати';
  if (param === 'radius') return 'Радиус';
  if (param === 'listed_under') return 'Вид сделка';
  if (param === 'city') return 'Град';
  if (param === 'neighborhood') return 'Квартал';
  if (param === 'district') return 'Област';
  if (param === 'categories') return 'Вид имот';
  return 'Филтър';
};

const SearchLayout = ({ details, filters }) => {
  const { query_string, id, name, ...filtersData } = details;
  const {
    NeighborhoodId,
    DistrictId,
    CityId,
    CurrentPage,
    listedUnder,
    Categories,
    CentreLatitude,
    CentreLongitude,
    ...parsedFilters
  } = queryString.parse(details.query_string);

  const allFiltersToShow = {
    ...(CentreLatitude &&
      CentreLongitude && { coordinates: `${CentreLatitude}, ${CentreLongitude}` }),
    ...filtersData,
    ...parsedFilters,
  };

  return (
    <div className="box-default s-search my-2 w-100">
      <div className="d-flex flex-column py-3 px-3 px-sm-5">
        <h5 className="h5 mb-0">{details.title}</h5>
        <Row className="py-2">
          {Object.keys(allFiltersToShow)
            .filter((data) => allFiltersToShow[data])
            .map((key) => (
              <Col key={key} sm={12} md={6} className="py-2">
                <span>{getName(key, filters)}: </span>
                <span>
                  {details[snakeCase(key)] || allFiltersToShow[key]}
                  {key === 'Radius' && ' км'}
                </span>
              </Col>
            ))}
        </Row>
        <div className="s-search__footer d-flex justify-content-between align-items-end border-top border-secodary pt-2">
          <Link to={`/buy-view?${details.query_string}`}>
            <Button className="btn animated" color="primary">
              <span>
                <FaSearch className="mr-2"></FaSearch>
              </span>
              <span>Виж резултати</span>
            </Button>
          </Link>
          <Button outline={true} color={'secondary'}>
            <FaTrashAlt className="mr-2"></FaTrashAlt>
            Изтрий
          </Button>
        </div>
      </div>
    </div>
  );
};

const SavedSearches = () => {
  const dispatch = useDispatch();
  const { items, total_pages } = useSelector(selectUserSearches);
  const filters = useSelector(selectAllFilters);

  useEffect(() => {
    dispatch(fetchUserSearches({ dispatch, settings, queryParams: {} }));
  }, []);

  const handlePageClick = (data) => {
    dispatch(
      fetchUserSearches({
        dispatch,
        settings,
        queryParams: { CurrentPage: data.selected + 1 },
      })
    );
  };

  return (
    <PrivateRoute>
      <DashboardLayout title="Запазени търсения">
        <Row className="p-0">
          {items.length > 0 ? (
            items.map((s) => {
              return <SearchLayout key={s.title} details={s} filters={filters}></SearchLayout>;
            })
          ) : (
            <h6>Все още нямате запазени търсения</h6>
          )}
          <Col className="d-flex justify-content-center my-5">
            {total_pages > 1 && (
              <Pagination handlePageClick={handlePageClick} pageCount={total_pages} forcePage={0} />
            )}
          </Col>
        </Row>
      </DashboardLayout>
    </PrivateRoute>
  );
};

export default SavedSearches;
